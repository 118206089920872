import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { ComponentProps, useMemo } from 'react';

import LoadingSpinner from '../components/LoadingSpinner';
import BaseLayout from './BaseLayout';
import styles from './ModalLayout.module.css';

type ModalLayoutProps = ComponentProps<typeof BaseLayout> & {
  showLogoHeader?: boolean;
  header?: string;
  loading?: boolean;
};

const ModalLayout = ({
  children,
  title,
  header,
  showLogoHeader,
  loading,
}: ModalLayoutProps) => {
  const headerType = useMemo(() => {
    switch (true) {
      case header === undefined && showLogoHeader === true:
        return 'logo';
      case header !== undefined:
        return 'text';
      default:
        return 'none';
    }
  }, [header, showLogoHeader]);

  return (
    <BaseLayout title={title}>
      <Row className={styles.wrapper} justify="space-around" align="middle">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 16 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          {headerType !== 'none' && (
            <div
              className={classNames({
                [styles.header]: true,
                [styles['text-header']]: headerType === 'text',
              })}
            >
              {headerType === 'logo' && (
                <img
                  alt="NALA for Business"
                  className={styles.logo}
                  src="/logos/full.svg"
                />
              )}

              {headerType === 'text' && header}
            </div>
          )}
          <div className={styles.card}>
            {loading ? <LoadingSpinner /> : children}
          </div>
        </Col>
      </Row>
    </BaseLayout>
  );
};

export default ModalLayout;
