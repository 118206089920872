import { useAuth0 } from '@auth0/auth0-react';
import { grpc as Grpc } from '@improbable-eng/grpc-web';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { AuthServiceProvider } from '../components/AuthProvider';
import api, { ApiGrpcError, LocalErrors } from '../lib/api';
import AuthRedirect from '../lib/authRedirect';
import { appEntryPoint } from '../pages/_app';

export enum AuthenticateType {
  SIGN_UP,
  LOGIN,
}

type UseAuthenticateReturn = [
  (provider: AuthServiceProvider) => PromiseLike<void>,
  {
    isLoading: boolean;
    isAuthenticating: boolean;
    isError: boolean;
  },
];

const useAuthenticate = (type: AuthenticateType): UseAuthenticateReturn => {
  const router = useRouter();

  const redirect = router.query.redirect as string;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);

  const listAccounts = api.useListAccountsQuery();

  const { getAccessTokenWithPopup } = useAuth0();

  useEffect(() => {
    if (listAccounts.isFetching) {
      return;
    }

    // The user has no auth an needs to login
    if (
      (listAccounts?.error as ApiGrpcError)?.code ===
      LocalErrors.OAUTH_LOGIN_REQUIRED
    ) {
      setIsLoading(false);
      return;
    }

    // User has authentication but no accounts
    if (
      (listAccounts?.error as ApiGrpcError)?.code === Grpc.Code.PermissionDenied
    ) {
      router.replace('/onboarding/getting-started');
      return;
    }

    // This isn't ideal as if an error was thrown this would assume no accounts which might not be the case
    const numberOfAccounts = listAccounts.data?.accounts.length ?? 0;

    // A user has signed up but has no business accounts
    if (numberOfAccounts === 0) {
      router.replace('/auth/sign-up');
      return;
    }

    // User has more than one user account
    if (numberOfAccounts > 0) {
      window.dataLayer.push({
        event: 'login',
      });

      router.push(redirect ?? AuthRedirect.consume() ?? appEntryPoint);
      return;
    }

    Sentry.captureException(
      new Error('authenticate hook is in an known state'),
      {
        extra: {
          numberOfAccounts,
          listAccountsError: !!listAccounts?.error,
        },
      },
    );
  }, [listAccounts]);

  const prompt = (() => {
    switch (type) {
      case AuthenticateType.SIGN_UP:
        return 'signup';
      case AuthenticateType.LOGIN:
        return 'login';
      default: {
        const unknownType: never = type;
        throw new TypeError(`Unknown type ${unknownType}`);
      }
    }
  })();

  const auth = useCallback(async (provider: AuthServiceProvider) => {
    try {
      setIsAuthenticating(true);
      await getAccessTokenWithPopup(
        {
          screen_hint: prompt,
          prompt: 'login',
          connection: provider,
        },
        { timeoutInSeconds: 900 },
      );

      listAccounts.refetch();
    } catch (e) {
      switch ((e as any)?.error) {
        case 'cancelled':
          break;
        default: {
          setIsError(true);
          Sentry.captureException(e);

          window.dataLayer.push({
            event: `${provider}_failed`,
            login_failed_reason: e,
          });
        }
      }
    } finally {
      setIsAuthenticating(false);
    }
  }, []);

  return [
    auth,
    {
      isLoading,
      isAuthenticating,
      isError,
    },
  ];
};

export default useAuthenticate;
