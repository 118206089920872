import React from 'react';

import styles from './styles.module.css';

type LoadingSpinnerProps = {
  caption?: string;
};

const LoadingSpinner = ({ caption = 'Loading...' }: LoadingSpinnerProps) => {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src="/images/loading.svg"
        alt="Loading..."
      />
      <div className={styles.label}>{caption}</div>
    </div>
  );
};

export default LoadingSpinner;
