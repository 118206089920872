import Head from 'next/head';
import React, { PropsWithChildren } from 'react';

type BaseLayoutProps = PropsWithChildren<{
  title: string;
}>;

const BaseLayout = ({ title, children }: BaseLayoutProps) => {
  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      {children}
    </>
  );
};

export default BaseLayout;
